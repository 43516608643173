.observation__question .observation_btn{
    margin: 33px 0 0 0;
    padding: 6px 10px;
}

.observation__table__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.add__question__form div{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.add__question__form div textarea, .add__question__form div select{
    padding: 5px;
    border-radius: 5px;
}