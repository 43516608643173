.error-text {
  color: red; }

.btnmarginTop {
  margin-top: 35px; }

.editable-container > a {
  color: #000000; }

.load-que-btn {
  margin-left: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.pointerClass {
  cursor: pointer; }

.noMarRight {
  margin-right: 0px !important; }

.noPadRight {
  padding-right: 0px !important; }

.ladda-label {
  font-size: 1em !important; }

.marginRight_10 {
  margin-right: 10px; }

.padTop {
  padding-top: 25px; }

.noPad {
  padding: 0px; }

.noMar {
  margin: 0px; }

.marTop_20 {
  margin-top: 20px; }

.padTop_5 {
  padding-top: 5px; }

.min_width {
  min-width: 150px; }

.td_btn_width {
  min-width: 360px; }

.padRight_5 {
  padding-right: 5px; }

.marBtm_20 {
  margin-bottom: 20px; }
