.report_month_picker .react-datepicker{
    width: 280px;
    height: 250px
}

.report_month_picker .react-datepicker .react-datepicker__header{
    font-size: 16px !important;
    width: 280px;
}

.report_month_picker .react-datepicker .react-datepicker__navigation-icon--previous{
    top: 8px !important;
}

.report_month_picker .react-datepicker .react-datepicker__navigation--next{
    top: 10px !important;
}

.report_month_picker .react-datepicker .react-datepicker__month-wrapper .react-datepicker__month-text{
    /* display: flex; */
    align-items: center;
    justify-content: center;
}

.report_month_picker .react-datepicker .react-datepicker__month-text{
    width: 85px !important;
    height: 46px !important;
    line-height: 45px !important;
}