.btn-search {
  float: right;
  width: 100px;
  height: 35px; }

.react-datepicker__input-container {
  display: block; }

.error-text {
  color: red; }

.btn-primary {
  color: #fff !important; }

.btn-info {
  color: #fff !important; }

.btn-warning {
  color: #fff !important; }

.marginRight_10 {
  margin-right: 10px; }

.search_btn {
  float: left;
  margin-top: 28px; }
