.rr_year_picker .react-datepicker__portal {
    width: 100%;
}
.rr_year_picker .react-datepicker {
    width: 195px;
    height: 195px;
}

.rr_year_picker .react-datepicker-year-header{
    width: 192px !important;
}

.rr_year_picker .react-datepicker__year-text {
    padding: 8px 10px;
}

.rr_year_picker .react-datepicker__navigation-icon--previous {
    right: 0 !important;
    top: 5px !important;
}

.rr_year_picker .react-datepicker__navigation-icon--next {
    left: 0 !important;
    top: 0 !important;
}

.rr_search_form .search_btn {
    margin-top: 23px;
    padding: 8px 30px;
    font-weight: 800;
}