.site-head .site-search {
  display: none; }

.view-header {
  padding-top: 0 !important; }

.toggle-dot {
  display: none; }

.error-text {
  color: red; }

.btnmarginTop {
  margin-top: 35px; }

.editable-container > a {
  color: #000000; }

.load-que-btn {
  margin-left: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.pointerClass {
  cursor: pointer; }

.noMarRight {
  margin-right: 0px !important; }

.noPadRight {
  padding-right: 0px !important; }

.ladda-label {
  font-size: 1em !important; }

.marginRight_10 {
  margin-right: 10px; }

.marginRight_5 {
  margin-right: 5px;
  margin-top: 10px; }

.padTop {
  padding-top: 25px; }

.noPad {
  padding: 0px; }

.noMar {
  margin: 0px; }

.marTop_20 {
  margin-top: 20px; }

.padTop_5 {
  padding-top: 5px; }

.min_width {
  min-width: 150px; }

.td_btn_width {
  min-width: 360px; }

.que_td_btn_width {
  min-width: 250px; }

.padRight_5 {
  padding-right: 5px; }

.marBtm_20 {
  margin-bottom: 20px; }

.cancel_btn {
  background: grey;
  color: white; }

.cancel_btn:hover {
  opacity: 0.8; }

.cus_select {
  padding: 2px 10px !important; }

.custom_select {
  padding: 2px 10px !important;
  width: 75% !important; }

.padLeft_22 {
  padding-left: 22px !important; }

.marTop_10 {
  margin-top: 10px; }

.table-responsive {
  overflow-y: hidden !important; }

.dropdown-item {
  padding: 2px 15px !important; }

.react-bs-table-pagination {
  margin-bottom: 100px; }
