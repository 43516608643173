.error-text {
  color: red; }

.btnmarginTop {
  margin-top: 35px; }

.editable-container > a {
  color: #000000; }

.load-btn {
  height: 35px; }

.pointerClass {
  cursor: pointer; }

.load-fac-btn {
  margin-left: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.marginRight {
  margin-right: 15px; }

.marginRight_10 {
  margin-right: 10px; }

.marginBtm {
  margin-bottom: 30px; }

.collapse {
  margin-left: 40px; }

.collapse_btn_main {
  margin-bottom: 1rem; }

.collapse_btn {
  border: solid 1px #144881;
  margin-bottom: 1rem; }

.collapse_btn_main button, .collapse_btn button {
  text-align: left;
  padding: 10px 15px; }

.cancel_btn {
  background: grey;
  color: white; }

.cancel_btn:hover {
  opacity: 0.8; }

.cust_alert {
  margin: 10px 0px !important; }
