.table_container {
    max-height: calc(100vh - 450px);
    overflow-y: scroll;
}

.color_green {
  background-color: #9fd29f !important
}
.color_red {
  background-color: #e18282 !important
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}